import { IoClose } from 'react-icons/io5';

const IntegrationModal = ({ isOpen, onClose, modalType, values, onChange, onSave, calendlyEvents }) => {
  console.log('values: ', values);
  if (!isOpen) return null;

  return (
    <div className='custom-popup' onClick={onClose}>
      <div className='popup-content position-relative' style={{ maxWidth: '500px' }} onClick={(e) => e.stopPropagation()}>
        <span className='lead-close-icon' onClick={onClose}>
          <IoClose />
        </span>
        <div className='popup-head chatbox-field'>
          <div className='chatbox-input-text input-GPT new mb-3'>
            <label className='opensans-medium text-white'>{modalType === 'ghl' ? 'GHL Api Key' : modalType === 'calendly' ? 'Calendly Api Key' : 'Hubspot Api Key'}</label>
            <input
              type='text'
              placeholder='Enter API Key'
              className='form-control chatbox-input'
              name={modalType === 'ghl' ? 'ghl_api_key' : modalType === 'calendly' ? 'calendly_api_key' : 'hubspot_api_key'}
              value={modalType === 'ghl' ? values.ghl_api_key : modalType === 'calendly' ? values.calendly_api_key : values.hubspot_api_key}
              onChange={onChange}
            />
          </div>
          {modalType === 'ghl' && (
            <div className='chatbox-input-text input-GPT new mb-3'>
              <label className='opensans-medium text-white'>Location ID</label>
              <input type='text' placeholder='Enter Location ID' className='form-control chatbox-input' name='ghl_location_id' value={values.ghl_location_id} onChange={onChange} />
            </div>
          )}
          {modalType === 'calendly' && (
            <div className='chatbox-input-text input-GPT new'>
              <label className='opensans-medium'>Calendly Event Id</label>
              <div className='chatbox-input-field'>
                <select className='form-control box-shadow-hover' name='calendly_event_id' value={values.calendly_event_id} onChange={onChange}>
                  <option hidden>Choose the Event ID</option>
                  {calendlyEvents.length > 0 ? (
                    calendlyEvents.map((event, index) => (
                      <option key={index} value={event?.event_id}>
                        {event?.event_name}
                      </option>
                    ))
                  ) : (
                    <option disabled>No events found</option>
                  )}
                </select>
              </div>
            </div>
          )}
        </div>
        <div className='popup-footer'>
          <button className='btn btn-submit-login mx-1 text-white' onClick={onSave} disabled={modalType === 'calendly' && !values.calendly_event_id}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default IntegrationModal;
