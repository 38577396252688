import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import DASHBOARD_ICON from '../assets/icons/ChirpfloIcon/Analytics-icon.svg';
import DASHBOARD_ACTIVEICON from '../assets/icons/ChirpfloIcon/Analytics-activeIcon.svg';
import CHATBOT_ICON from '../assets/icons/ChirpfloIcon/Train-bot-icon.svg';
import CHATBOT_ACTIVEICON from '../assets/icons/ChirpfloIcon/Train-bot-activeIcon.svg';
import SETTINGS_ICON from '../assets/icons/ChirpfloIcon/Setting-icon.svg';
import SETTINGS_ACTIVEICON from '../assets/icons/ChirpfloIcon/Setting-activeIcon.svg';
import SHARE_ICON from '../assets/icons/Share_icon.svg';
import BELL_ICON from '../assets/icons/Bell_icon.svg';
import USER_ICON from '../assets/icons/User1_icon.svg';
import LEAD_ICON from '../assets/icons/ChirpfloIcon/Generated-leads-icon.svg';
import LEAD_ACTIVEICON from '../assets/icons/ChirpfloIcon/Generated-leads-activeIcon.svg';
import INTEGRATION_ICON from '../assets/icons/ChirpfloIcon/Intergrations-icon.svg';
import INTEGRATION_ACTIVEICON from '../assets/icons/ChirpfloIcon/Integrations-activeIcon.svg';
import '../assets/styles/Sidebar.css';
import INTELICHAT_LOGO from '../assets/icons/ChirpfloIcon-V2.0/chirpflo-icon.svg';
import CHAT_ICON from '../assets/icons/ChirpfloIcon-V2.0/conversations-icon.svg';
import DOCS_ICON from '../assets/icons/ChirpfloIcon-V2.0/wordcount-icon.svg';
import CHIRPFLO_ICON from '../assets/icons/ChirpfloIcon-V2.0/sidebarbox-img.svg';
import { Link } from 'react-router-dom';
import { subContext } from '../App';

const Sidebar = () => {
  const location = useLocation();
  const Navigate = useNavigate();
  const subsFlag = useContext(subContext);

  return (
    <div className='sidebar-menu-left'>
      <div className='sidebar-conatiner'>
        <div className='logo-icon'>
          <NavLink
            to={
              subsFlag?.subscriptionFlag?.user?.subscription?.name === 'Pro' || subsFlag?.subscriptionFlag?.user?.subscription?.name === 'Basic'
                ? '/dashboard'
                : '/chatbot/websitescrape'
            }
          >
            <img src={INTELICHAT_LOGO} alt='logo' className='full-logo' />
          </NavLink>
        </div>
        <nav className='sidebar-nav-content'>
          <ul className='m-0'>
            {subsFlag?.subscriptionFlag?.user?.subscription?.name != null && (
              <li
                className={`sidebar-icon ${location?.pathname === '/dashboard' && 'active-border-left'}`}
                onMouseOver={(e) => {
                  e.currentTarget.querySelector('h2').style.color = 'white';
                  e.currentTarget.querySelector('img').src = DASHBOARD_ACTIVEICON;
                }}
                onMouseOut={(e) => {
                  e.currentTarget.querySelector('h2').style.color = '';
                  e.currentTarget.querySelector('img').src = location?.pathname === '/dashboard' ? DASHBOARD_ACTIVEICON : DASHBOARD_ICON;
                }}
              >
                <NavLink to='/dashboard'>
                  <img src={location?.pathname === '/dashboard' ? DASHBOARD_ACTIVEICON : DASHBOARD_ICON} alt='dashboard-icon' />
                  <h2 className={`hover-effect opensans-bold ${location?.pathname === '/dashboard' && 'hoverActive'}`}>Analytics</h2>
                </NavLink>
              </li>
            )}
            <li
              className={`sidebar-icon ${
                (location?.pathname === '/chatbot' ||
                  location?.pathname === '/chatbot/knowledgebase' ||
                  location?.pathname === '/chatbot/websitescrape' ||
                  location?.pathname === '/chatbot/testbot' ||
                  location?.pathname === '/chatbot/knowledgebasefaq' ||
                  location?.pathname === '/chatbot/customizewidget') &&
                'active-border-left'
              }`}
              onMouseOver={(e) => {
                e.currentTarget.querySelector('h2').style.color = 'white';
                e.currentTarget.querySelector('img').src = CHATBOT_ACTIVEICON;
              }}
              onMouseOut={(e) => {
                e.currentTarget.querySelector('h2').style.color = '';
                e.currentTarget.querySelector('img').src = location.pathname.includes('/chatbot') ? CHATBOT_ACTIVEICON : CHATBOT_ICON;
              }}
            >
              <NavLink to='/chatbot/websitescrape'>
                <img
                  src={
                    location?.pathname === '/chatbot' ||
                    location?.pathname === '/chatbot/knowledgebase' ||
                    location?.pathname === '/chatbot/websitescrape' ||
                    location?.pathname === '/chatbot/customizewidget'
                      ? CHATBOT_ACTIVEICON
                      : CHATBOT_ICON
                  }
                  alt='chatbot-icon'
                />
                <h2
                  className={`hover-effect opensans-bold ${
                    (location?.pathname === '/chatbot' ||
                      location?.pathname === '/chatbot/knowledgebase' ||
                      location?.pathname === '/chatbot/websitescrape' ||
                      location?.pathname === '/chatbot/customizewidget') &&
                    'hoverActive'
                  }`}
                  id='train-bot'
                >
                  Train Bot
                </h2>
              </NavLink>
            </li>

            <div id='collapselivechat' className='accordion-collapse collapse' aria-labelledby='train-bot' data-bs-parent='#accordionlivechat'>
              <div className='accordion-body'>
                <div className='accordion-modify'>
                  <Link to='/agentknowledge/knowledgebase' className={`opensans-semibold ${location?.pathname === '/agentknowledge/knowledgebase' ? 'hoverActive' : ''}`}>
                    Auto Response
                  </Link>
                </div>
              </div>
            </div>

            {subsFlag?.subscriptionFlag?.user?.subscription?.name === 'Pro' && (
              <li
                className={`sidebar-icon ${location?.pathname === '/leads' && 'active-border-left'}`}
                onMouseOver={(e) => {
                  e.currentTarget.querySelector('h2').style.color = 'white';
                  e.currentTarget.querySelector('img').src = LEAD_ACTIVEICON;
                }}
                onMouseOut={(e) => {
                  e.currentTarget.querySelector('h2').style.color = '';
                  e.currentTarget.querySelector('img').src = location?.pathname === '/leads' ? LEAD_ACTIVEICON : LEAD_ICON;
                }}
              >
                <NavLink to='/leads'>
                  <img src={location?.pathname === '/leads' ? LEAD_ACTIVEICON : LEAD_ICON} alt='share-icon' />
                  <h2 className={`hover-effect opensans-bold ${location?.pathname === '/leads' && 'hoverActive'}`}>Generated Leads</h2>
                </NavLink>
              </li>
            )}
            {subsFlag?.subscriptionFlag?.user?.subscription?.name === 'Pro' && (
              <li
                className={`sidebar-icon ${location?.pathname === '/integrations' && 'active-border-left'}`}
                onMouseOver={(e) => {
                  e.currentTarget.querySelector('h2').style.color = 'white';
                  e.currentTarget.querySelector('img').src = INTEGRATION_ACTIVEICON;
                }}
                onMouseOut={(e) => {
                  e.currentTarget.querySelector('h2').style.color = '';
                  e.currentTarget.querySelector('img').src = location?.pathname === '/integrations' ? INTEGRATION_ACTIVEICON : INTEGRATION_ICON;
                }}
              >
                <NavLink to='/integrations'>
                  <img src={location?.pathname === '/integrations' ? INTEGRATION_ACTIVEICON : INTEGRATION_ICON} alt='share-icon' />
                  <h2 className={`hover-effect opensans-bold ${location?.pathname === '/leads' && 'hoverActive'}`}>Integrations</h2>
                </NavLink>
              </li>
            )}
            <li
              className={`sidebar-icon ${(location?.pathname === '/setting' || location?.pathname === '/setting/changepassword') && 'active-border-left'}`}
              onMouseOver={(e) => {
                e.currentTarget.querySelector('h2').style.color = 'white';
                e.currentTarget.querySelector('img').src = SETTINGS_ACTIVEICON;
              }}
              onMouseOut={(e) => {
                e.currentTarget.querySelector('h2').style.color = '';
                e.currentTarget.querySelector('img').src = location.pathname.includes('/setting') ? SETTINGS_ACTIVEICON : SETTINGS_ICON;
              }}
            >
              <NavLink to='/setting'>
                <img src={location?.pathname === '/setting' || location?.pathname === '/setting/changepassword' ? SETTINGS_ACTIVEICON : SETTINGS_ICON} alt='chatbot-icon' />
                <h2
                  className={`hover-effect opensans-bold ${(location?.pathname === '/setting' || location?.pathname === '/setting/changepassword') && 'hoverActive'}`}
                  id='setting'
                >
                  Setting
                </h2>
              </NavLink>
            </li>

            <div className='sidebar-box position-relative'>
              <div className='d-flex align-items-center sidebar-box-text'>
                <img src={CHAT_ICON} alt='chat-img' className='sidebar-box-logo' />
                <div className='text-start'>
                  <h6 className='mb-0 opensans-semibold text-white'>Conversations</h6>
                  <span className='sidebar-box-counter opensans-regular'>
                    {subsFlag?.conversionData?.con || 0} / {subsFlag?.conversionData?.total || 0}
                  </span>
                </div>
              </div>
              <div className='d-flex align-items-center sidebar-box-text mt-3'>
                <img src={DOCS_ICON} alt='docs-img' className='sidebar-box-logo' />
                <div className='text-start'>
                  <h6 className='mb-0 opensans-semibold'>Word Count </h6>
                  <span className='sidebar-box-counter opensans-regular'>{subsFlag?.conversionData?.word_count || 0} / 20000</span>
                </div>
              </div>
              <div className='mt-3'>
                <button className='btn btn-submit-login rounded opensans-semibold font14 w-100' onClick={() => Navigate('/subscription ')}>
                  Upgrade
                </button>
              </div>
              <img src={CHIRPFLO_ICON} alt='chirpflo-icon' className='chirpflo-icon' />
            </div>
          </ul>
          <ul className='m-0 d-none'>
            <li className='sidebar-icon'>
              <NavLink to='/upcoming'>
                <img src={SHARE_ICON} alt='share-icon' />
              </NavLink>
            </li>
            <li className='sidebar-icon'>
              <NavLink to='/notification'>
                <img src={BELL_ICON} alt='bell-icon' />
              </NavLink>
            </li>
            <li className='sidebar-icon'>
              <NavLink to='/profile'>
                <img src={USER_ICON} alt='user-icon' />
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
