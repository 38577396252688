import React from 'react';

const CalendlyEmbed = ({ url }) => {
  return (
    <div style={styles.container}>
      <div style={styles.iframeContainer}>
        <iframe src={url} title='calendar' style={styles.iframe}></iframe>
      </div>
    </div>
  );
};

const styles = {
  container: {
    textAlign: 'center',
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    marginTop: '10px',
  },
  iframeContainer: {
    backgroundColor: '#ffffff',
    padding: '10px',
    borderRadius: '10px',
    margin: 'auto',
  },
  iframe: {
    width: '100%',
    height: '850px',
    border: 'none',
    borderRadius: '10px',
  },
};

export default CalendlyEmbed;
