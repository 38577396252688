import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { clearLocalStorage, getLocalStorage, getWithToken, patchWithToken, postWithToken } from '../API/Api';
import { toast } from 'react-toastify';
import Loader from '../components/Loader';

const GHLConfiguration = () => {
  const [loader, setLoader] = useState(false);
  const [apiTokenData, setApiTokenData] = useState('');
  const [values, setValues] = useState({
    ghl_api_key: '',
    ghl_location_id: '',
    hubspot_api_key: '',
  });
  const [errors, setErrors] = useState('');

  const Navigate = useNavigate();

  useEffect(() => {
    let token = getLocalStorage('apiToken');
    if (token) {
      const parsedToken = JSON.parse(token);
      setApiTokenData(parsedToken);
      fetchGHLConfigurationDetails(parsedToken);
    } else {
      Navigate('/login');
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  // const validateInputs = () => {
  //   let newErrors = {};
  //   if (!values.ghl_api_key.trim()) {
  //     newErrors.ghl_api_key = 'GHL API Key is required';
  //   }
  //   if (!values.ghl_location_id.trim()) {
  //     newErrors.ghl_location_id = 'GHL Location ID is required';
  //   }
  //   if (!values.hubspot_api_key.trim()) {
  //     newErrors.hubspot_api_key = 'Hubspot API Key is required';
  //   }
  //   setErrors(newErrors);
  //   return Object.keys(newErrors).length === 0;
  // };

  const handleSaveClick = async () => {
    // if (!validateInputs()) return;

    try {
      const payload = {
        ghl_api_key: values.ghl_api_key,
        ghl_location_id: values.ghl_location_id,
        hubspot_api_key: values.hubspot_api_key,
      };

      let response;
      if (values?.id) {
        response = await patchWithToken(`crm_configurations/${values.id}`, apiTokenData, payload);
      } else {
        response = await postWithToken('crm_configurations', apiTokenData, payload);
      }

      if (response.status === 200 || response.status === 201) {
        fetchGHLConfigurationDetails(apiTokenData);
        toast.success(response.message);
      } else if (response.status === 500 || response.status === 400) {
        toast.error(response.message);
      } else if (response.code === 'token_not_valid') {
        clearLocalStorage();
        toast.error('Token not valid. Please log in again.');
      } else {
        toast.error('Unexpected error occurred');
      }
    } catch (error) {
      console.error('Error saving GHL configuration:', error);
      toast.error('Something went wrong while saving.');
    }
  };

  const fetchGHLConfigurationDetails = (token) => {
    setLoader(true);
    getWithToken('crm_configurations', token)
      .then((response) => {
        if (response.status === 200 && response.data && response.data.length > 0) {
          setValues(response?.data[0]);
        }
      })
      .catch((error) => {
        console.log('error: ', error);
        setLoader(false);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  return (
    <>
      <div className='row m-0 mt-5 ps-md-5 ps-sm-3 ps-3 mb-5'>
        <div className='col-xxl-7 col-xl-7 col-lg-12 col-md-12 col-sm-12 col-12 p-0'>
          <div className='chatbox-field bg-themeLightBlue'>
            <div className='px-2 px-md-3 px-lg-2 px-xl-3 px-xxl-5'>
              <div className='chatbox-input-text input-GPT new mb-3'>
                <label className='opensans-medium'>GHL API Key</label>
                <input
                  type='text'
                  name='ghl_api_key'
                  value={values.ghl_api_key}
                  onChange={handleInputChange}
                  className='form-control chatbox-input box-shadow-hover'
                  autoComplete='off'
                  placeholder='Enter your GHL API Key'
                />
                {/* {errors.ghl_api_key && <small className='text-danger'>{errors.ghl_api_key}</small>} */}
              </div>
              <div className='chatbox-input-text input-GPT new mb-3'>
                <label className='opensans-medium'>GHL Location ID</label>
                <input
                  type='text'
                  name='ghl_location_id'
                  value={values.ghl_location_id}
                  onChange={handleInputChange}
                  className='form-control chatbox-input box-shadow-hover'
                  autoComplete='off'
                  placeholder='Enter your GHL Location ID'
                />
                {/* {errors.ghl_location_id && <small className='text-danger'>{errors.ghl_location_id}</small>} */}
              </div>
              <div className='chatbox-input-text input-GPT new mb-3'>
                <label className='opensans-medium'>Hubspot API Key**</label>
                <input
                  type='text'
                  name='hubspot_api_key'
                  value={values.hubspot_api_key}
                  onChange={handleInputChange}
                  className='form-control chatbox-input box-shadow-hover'
                  autoComplete='off'
                  placeholder='Enter your Hubspot API Key'
                />
              </div>
              <div className='d-flex align-items-center justify-content-between mt-4'>
                <button className='btn btn-submit-login rounded-3 mt-lg-0' onClick={handleSaveClick}>
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader && (
        <div className='loader-center'>
          <Loader />
        </div>
      )}
    </>
  );
};

export default GHLConfiguration;
