import React, { useState, useEffect } from 'react';
import { IoSend } from 'react-icons/io5';
import ThreeDotLoader from '../components/ThreeDotLoader';
import { toast } from 'react-toastify';
import Linkify from 'react-linkify';
import { useLocation } from 'react-router-dom';
import { clearLocalStorage, deleteWithoutToken, getLocalStorage, getWithoutToken, getWithToken, postWithoutToken } from '../API/Api';
import CHATBOT_ICON from '../assets/icons/ChirpfloIcon-V2.0/chatbot-icon.svg';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import SIDEBAR_BOX_IMG from '../assets/icons/ChirpfloIcon/chatbot-icon.svg';
import CHIRPFLO_LOGO from '../assets/icons/ChirpfloIcon-V2.0/chirpflo-icon.svg';
import { BiTrash } from 'react-icons/bi';
import { IoMdClose } from 'react-icons/io';
import ScrollToBottom from './ScrollToBottom';
import CalendlyEmbed from './CalendlyEmbed';

const formatText = (text) => {
  return text.split(/\*\*(.*?)\*\*/g).map((part, index) => (index % 2 === 1 ? <strong key={index}>{part}</strong> : part));
};

const WidgetChatbot = (props) => {
  const [chatData, setChatData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [values, setValues] = useState('');
  const [widgetDetails, setWidgetDetails] = useState('');
  const [openChatbox, setOpenChatbox] = useState(false);
  const [companyID, setCompanyID] = useState('');
  const widgetData = useLocation();
  const [sessionId, setSessionId] = useState('');
  const [hideButtons, setHideButtons] = useState(false);
  const [hideLeadClosingButtons, setHideLeadClosingButtons] = useState(false);
  const params = new URLSearchParams(window.location.search);
  const [customDisabled, setCustomDisabled] = useState(false);
  const [retrainPopup, setRetrainPopup] = useState(false);
  const [welBubblePopup, setWelBubblePopup] = useState(false);
  const [deleteConfirmPopup, setDeleteConfirmPopup] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isLeadButtonHide, setIsLeadButtonHide] = useState(false);

  const initialFormValue = {
    name: '',
    email: '',
    phone_number: '',
  };
  const [showForm, setShowForm] = useState(false);
  const [showCalendar, setShowCalendar] = useState({
    show: false,
    link: '',
  });
  const [formData, setFormData] = useState(initialFormValue);
  const [showFormButton, setShowFormButton] = useState(false);
  const [showRecommendation, setShowRecommendation] = useState(false);
  const [leadGenerate, setLeadGenerate] = useState(false);

  const { name, email, phone_number } = formData;
  const paramsID = params.get('id');
  const paramsCompanyID = params.get('company_id');
  const paramsPreData = params.get('previewData');
  const paramsleadGenerated = params.get('leadGenerated');

  const [welcomeDetails, setWelcomeDetails] = useState({
    welcome_msg: null,
    lead_choice: null,
    lead_status: false,
    generate_lead_choice: null,
    create_lead_choice: null,
    is_calendar: false,
    lead_form: false,
  });

  const getBotDetails = (token) => {
    getWithToken('company/', token)
      .then((response) => {
        if (response.status === 201) {
          setCompanyID(response?.data?.id);
          getWelcomeMsg(response?.data?.id);
        } else if (response.code === 'token_not_valid') {
          clearLocalStorage();
        }
      })
      .catch((error) => {
        console.error('error: ', error);
        toast.error('Something went wrong');
      });
  };

  useEffect(() => {
    let token = getLocalStorage('apiToken');
    if (token) {
      getBotDetails(JSON.parse(token));
    }
  }, [chatData]);

  useEffect(() => {
    let debounce = null;

    if (paramsID || props.widgetID) {
      if (debounce != null) {
        clearTimeout(debounce);
      }
      debounce = setTimeout(() => {
        getWidgetDetails(paramsID || props.widgetID);
      }, 500);
    }

    return () => {
      clearTimeout(debounce);
    };
  }, [widgetData, chatData, paramsID, props.widgetID, props.widgetAllData]);

  useEffect(() => {
    setSessionId(generateRandomSessionId());
  }, []);

  useEffect(() => {
    if (widgetDetails?.buble_timer_count && widgetDetails?.buble_active_status) {
      if (widgetDetails.status && widgetDetails.buble_active_status) {
        if (!paramsleadGenerated && !widgetDetails?.popup_status) {
          setTimeout(() => {
            setWelBubblePopup(true);
          }, widgetDetails?.buble_timer_count * 1000);
        }
      }
    } else {
      setWelBubblePopup(false);
    }
  }, [widgetDetails]);

  const generateRandomSessionId = () => {
    const numbers = '0123456789';
    const length = 8;
    let randomId = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * numbers.length);
      randomId += numbers.charAt(randomIndex);
    }
    return randomId;
  };

  const getShowRecommendation = (id) => {
    getWithoutToken(`lead_answers_retrieve/${id}`)
      .then((response) => {
        if (response.status === 200) {
          setShowRecommendation(response?.data);
        }
      })
      .catch((error) => {
        console.error('error: ', error);
        toast.error('Something went wrong');
      });
  };

  const postChatData = (e) => {
    e.preventDefault();
    if (values.trim().length > 0) {
      let newObj = { prmt: values };
      chatData.push(newObj);
      let body = {
        prompt: values,
        session_id: sessionId,
      };
      setValues('');
      setLoader(true);
      setIsButtonDisabled(true);
      setShowRecommendation(null);
      setShowCalendar({});
      chatPostApi(body);
    } else {
      toast.dismiss();
      toast.error('Message is blank.');
      setLoader(false);
    }
  };

  const chatPostApi = async (body) => {
    try {
      setCustomDisabled(true);
      var companyIdToUse = companyID || paramsCompanyID;

      const response = await postWithoutToken('LoginChatGPTAPI?company=' + companyIdToUse, body);

      if (response.status === 200) {
        let tempChatData = [...chatData];
        response.data.forEach(({ text, id, closing_lead_choice, closing_chatbot, lead_form, calendar, recommendation, ...choice }) => {
          const tempData = { choice, text, id };

          if (!welcomeDetails?.lead_choice) setHideButtons(false);
          if (closing_lead_choice !== undefined) setHideLeadClosingButtons(false);
          if (closing_chatbot) setTimeout(() => setOpenChatbox(false), 2000);
          if (lead_form) setShowForm(true);
          if (calendar)
            setShowCalendar({
              show: true,
              link: calendar,
            });
          if (recommendation) getShowRecommendation(recommendation);

          tempChatData.push(tempData);
        });

        setChatData(tempChatData);
      } else if (response.status === 400) {
        toast.error(response.message);
      } else if (response.status === 403) {
        toast.error(response?.message);
      } else if (response.code === 'token_not_valid') {
        clearLocalStorage();
      }
    } catch (error) {
      console.error('Error in chatPostApi:', error);
      toast.error('An unexpected error occurred.');
    } finally {
      setCustomDisabled(false);
      setIsButtonDisabled(false);
      setLoader(false);
    }
  };

  const handleChoice = (e, Data) => {
    setHideButtons(true);
    setHideLeadClosingButtons(true);
    setIsButtonDisabled(true);
    let newObj = { prmt: Data };
    chatData.push(newObj);
    let body = {
      prompt: Data,
      session_id: sessionId,
    };
    setLoader(true);
    setOpenChatbox(true);
    chatPostApi(body);
  };

  const getWidgetDetails = (id) => {
    if (paramsPreData || props.widgetID) {
      getWithoutToken(`WidgetGetAPI/${id}`)
        .then((response) => {
          console.log('responsess: ', response);
          if (response.status === 200) {
            setWidgetDetails(response?.data);
            getWelcomeMsg(response?.data?.company);

            if (paramsleadGenerated) {
              setLeadGenerate(true);
              setOpenChatbox(true);
            }
            if (response?.data?.status && response?.data?.popup_status) {
              setTimeout(() => {
                if (widgetData?.pathname === '/preview') {
                  setWelBubblePopup(false);
                  setOpenChatbox(true);
                }
              }, response?.data?.timer_count * 1000);
            }
          } else if (response.code === 'token_not_valid') {
            clearLocalStorage();
          }
        })
        .catch((error) => {
          // toast.error('Something went wrong');
        });
    }
  };

  const getWelcomeMsg = (companyID) => {
    var companyIdToUse = companyID || paramsCompanyID;
    getWithoutToken('welcomesms_linkAPI/?company=' + companyIdToUse)
      .then((response) => {
        if (response.status === 200) {
          setWelcomeDetails({
            welcome_msg: response?.data?.welcome_msg,
            lead_choice: response?.data?.lead_choice,
            lead_status: response?.data?.status,
            generate_lead_choice: response?.data?.generate_lead_choice,
            create_lead_choice: response?.data?.create_lead_choice,
            is_calendar: response?.data?.is_calendar,
            lead_form: response?.data?.lead_form,
          });
        } else if (response.code === 'token_not_valid') {
          clearLocalStorage();
        }
      })
      .catch((error) => {
        toast.error('Something went wrong');
      });
  };

  const handleFirstButtonClick = (e, data) => {
    setShowForm(true);
  };

  const handleFormSubmit = (e) => {
    setIsButtonDisabled(true);
    e.preventDefault();
    var companyIdToUse = companyID || paramsCompanyID;
    const payload = {
      name: name,
      email: email,
      phone_number: phone_number,
      recipient_id: sessionId,
    };
    postWithoutToken('Create_LeadInformationAPI?company=' + companyIdToUse, payload)
      .then((response) => {
        if (response.status === 201) {
          setFormData(initialFormValue);
          setShowForm(false);
          handleChoice(e, `${formData.name} ${formData.email} ${formData.phone_number}`);
        } else if (response.status === 400) {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        console.error('error: ', error);
        toast.error('Something went wrong');
        setIsButtonDisabled(false);
      });
  };

  const hanldeClearData = () => {
    var companyIdToUse = companyID || paramsCompanyID;
    deleteWithoutToken('LoginChatGPTAPI?company=' + companyIdToUse)
      .then((response) => {
        if (response.status === 200) {
          setChatData([]);
          setValues('');
          setHideButtons(false);
          setShowRecommendation(null);
          setShowFormButton(false);
          setWelBubblePopup(false);
          setShowForm(false);
          setIsLeadButtonHide(false);
          setIsButtonDisabled(false);
          setDeleteConfirmPopup(false);
          setRetrainPopup(false);
          setSessionId(generateRandomSessionId());
          setShowCalendar({});
        }
      })
      .catch((error) => {
        console.error('error: ', error);
        toast.error('Something went wrong');
      });
  };

  function MessageComponent({ message }) {
    const lines = message.split('\n');

    return (
      <>
        {lines.map((line, index) => (
          <p key={index} className='m-0 inter-regular font16'>
            {line || 'Hey,'}
          </p>
        ))}
      </>
    );
  }

  const fetchCalendlyLink = async () => {
    try {
      const companyIdToUse = companyID || paramsCompanyID;
      const url = `create_calendly_scheduling_link_withouttoken/?company=${companyIdToUse}`;

      const response = await postWithoutToken(url, { session_id: sessionId });

      if (response?.status === 200) {
        return response.data;
      }

      throw new Error('Failed to fetch Calendly link');
    } catch (error) {
      console.error('Error fetching Calendly link:', error);
      toast.error('Something went wrong');
      return null;
    }
  };

  return (
    <>
      {widgetData?.pathname === '/preview' && (
        <div className='widgetChabot-iframe h-100'>
          <iframe src={widgetDetails?.website} title='Tutorials'></iframe>
        </div>
      )}

      <div
        className={`custom-chat-widget ${openChatbox ? (leadGenerate ? 'widgetLeadOpen' : 'widgetOpen') : ''}`}
        style={{
          background: widgetDetails?.background_color?.includes('http') ? `url(${widgetDetails?.background_color})` : widgetDetails?.background_color,
          backgroundSize: 'cover',
          transition: !leadGenerate ? '0.5s all' : '',
        }}
      >
        <div className='custom-chat-header flex-column justify-content-center'>
          {!paramsleadGenerated && (
            <div
              className='close-arrow'
              style={{ background: widgetDetails?.client_bubble_color }}
              onClick={() => {
                setOpenChatbox(false);
                setWelBubblePopup(false);
                setDeleteConfirmPopup(false);
                setLeadGenerate(false);
              }}
            >
              <MdOutlineKeyboardArrowRight style={{ color: widgetDetails?.bubble_text_color }} />
            </div>
          )}
          <div className='' style={{ maxWidth: 'fit-content', maxHeight: '50px' }}>
            <img src={widgetDetails?.chatbot_logo || CHIRPFLO_LOGO} alt='chatbox-logo' className='w-100 h-100' style={{ objectFit: 'contain' }} />
          </div>
          <div className='custom-chat-text mt-2'>
            <p className='inter-bold' style={{ color: widgetDetails?.text_color }}>
              {widgetDetails?.heading}
            </p>
          </div>
        </div>
        <div className='custom-hr m-0' style={{ borderTop: `2px solid ${widgetDetails?.client_bubble_color}` }}></div>
        <div className='custom-chat-body p-3 pb-0'>
          <div className={`custom-chat-body-box ${!hideButtons && welcomeDetails?.lead_choice !== null && 'pt-5 mb-0'}`}>
            <div className='w-100'>
              {welcomeDetails?.welcome_msg !== null && (
                <>
                  {welcomeDetails?.lead_status === true ? (
                    <div
                      className={`chatbot-welcome-body ${hideButtons && welcomeDetails?.lead_choice !== null ? 'p-0' : 'chatbot-withBoxShadow'}`}
                      style={{
                        background: hideButtons && welcomeDetails?.lead_choice !== null ? 'initial' : widgetDetails?.color,
                      }}
                    >
                      {!hideButtons && (
                        <>
                          {!showForm && (
                            <div
                              className='d-flex justify-content-center align-items-center chatbot-popup-icon'
                              style={{
                                width: '80px',
                                height: '80px',
                                minWidth: '80px',
                                minHeight: '80px',
                                borderRadius: '50%',
                                backgroundColor: widgetDetails?.color,
                                position: 'absolute',
                                top: 0,
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                              }}
                            >
                              <img
                                src={widgetDetails?.chatbot_avtar || SIDEBAR_BOX_IMG}
                                alt='chatbot-icon'
                                // className='w-100'
                                style={{ width: '90%', height: 'auto', objectFit: 'cover' }}
                              />
                            </div>
                          )}
                          <div className={`d-flex align-items-center ${showForm && 'mt-3'}`}>
                            {showForm && (
                              <div className='chatbox-user-icon me-3'>
                                <img src={widgetDetails?.chatbot_avtar || SIDEBAR_BOX_IMG} alt='chatbot-icon' />
                              </div>
                            )}
                            <div
                              className={`opensans-regular ${showForm ? 'custom-chat-anstext' : 'welcome-text text-white mt-5'}`}
                              style={{ background: showForm && widgetDetails?.color }}
                            >
                              <p className='m-0 inter-bold text-center font16' style={{ color: widgetDetails?.agent_bubble_text_color }}>
                                {welcomeDetails?.welcome_msg}
                              </p>
                            </div>
                          </div>
                        </>
                      )}
                      {!hideButtons && welcomeDetails?.lead_choice != null && (
                        <div className='d-flex flex-column align-items-center mt-2 w-100'>
                          {welcomeDetails?.lead_choice.map((choice, index) => (
                            <React.Fragment key={index}>
                              <button
                                className='btn btn-submit-login inter-regular font14'
                                style={{
                                  color: index === 0 ? widgetDetails?.bubble_text_color : widgetDetails?.agent_bubble_text_color,
                                  background: index === 0 ? widgetDetails?.client_bubble_color : widgetDetails?.color,
                                  border: `2px solid ${widgetDetails?.client_bubble_color}`,
                                  borderRadius: '34px',
                                  maxWidth: 'unset',
                                  height: '40px',
                                  marginTop: '1rem',
                                }}
                                onClick={
                                  index === 0
                                    ? (e) => handleChoice(e, choice)
                                    : (e) => {
                                        handleChoice(e, choice);
                                        setShowFormButton(true);
                                      }
                                }
                              >
                                {choice}
                              </button>

                              <style>
                                {` 
                                   
                                   .hr-line::after {                     
                                     background-color: ${widgetDetails?.text_color};
                                   }
                                   .hr-line::before {                      
                                     background-color: ${widgetDetails?.text_color};
                                   }

                                   .chat-group-btn:hover {
                                     background-color: ${widgetDetails?.client_bubble_color};
                                     color: ${widgetDetails?.bubble_text_color}; 
}
                                 `}
                              </style>
                            </React.Fragment>
                          ))}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className='d-flex align-items-center mt-3'>
                      <div className='custom-chat-anstext' style={{ background: widgetDetails?.color }}>
                        <div className='chatbox-user-icon mb-2'>
                          <img src={widgetDetails?.chatbot_avtar || SIDEBAR_BOX_IMG} alt='chatbot-icon' />
                        </div>
                        <p className='m-0' style={{ color: widgetDetails?.agent_bubble_text_color }}>
                          {welcomeDetails?.welcome_msg}
                        </p>
                      </div>
                    </div>
                  )}
                </>
              )}
              {chatData.length > 0 &&
                chatData?.map((obj, index) => {
                  return (
                    <>
                      {obj.prmt && (
                        <div className='d-flex align-items-center justify-content-end mt-3'>
                          <div className='custom-chat-quetext' style={{ background: widgetDetails?.client_bubble_color }}>
                            <p className='m-0 inter-regular font14' style={{ color: widgetDetails?.bubble_text_color }}>
                              {obj?.prmt}
                            </p>
                          </div>
                        </div>
                      )}
                      {obj?.text && (
                        <div className='d-flex editdata-chatbox mt-3'>
                          <div className='custom-chat-anstext' style={{ background: widgetDetails?.color }}>
                            <div className='d-flex align-items-center mb-2'>
                              <div className='widget-user-icon position-relative me-2'>
                                <img src={widgetDetails?.chatbot_avtar || SIDEBAR_BOX_IMG} alt='chatbot-icon' />
                              </div>
                              <span className='inter-bold font14' style={{ color: widgetDetails?.agent_bubble_text_color }}>
                                {widgetDetails?.bot_name}
                              </span>
                            </div>
                            <p className='m-0 inter-regular font14' style={{ color: widgetDetails?.agent_bubble_text_color, whiteSpace: 'pre-line' }}>
                              <Linkify
                                componentDecorator={(decoratedHref, decoratedText, key) => (
                                  <a target='blank' href={decoratedHref} key={key}>
                                    {decoratedText}
                                  </a>
                                )}
                              >
                                {formatText(obj?.text)}
                              </Linkify>
                            </p>
                          </div>
                        </div>
                      )}
                      {!hideLeadClosingButtons && obj?.choice?.closing_lead_choice !== undefined && !showForm && (
                        <div className='d-flex align-items-center justify-content-center mt-3'>
                          {obj?.choice?.closing_lead_choice?.length > 0 &&
                            obj?.choice?.closing_lead_choice?.map((choice, index) => (
                              <button
                                key={index}
                                className={`btn btn-submit-login preview-btn-style rounded  ${index === 0 ? 'me-3' : ''}`}
                                style={{ background: widgetDetails?.client_bubble_color, borderColor: widgetDetails?.client_bubble_color, color: widgetDetails?.text_color }}
                                onClick={
                                  index === 0
                                    ? (e) => handleFirstButtonClick(e, choice)
                                    : (e) => {
                                        handleChoice(e, choice);
                                        setShowFormButton(true);
                                        setShowForm(false);
                                      }
                                }
                              >
                                {choice}
                              </button>
                            ))}
                        </div>
                      )}
                    </>
                  );
                })}
              {loader ? (
                <>
                  <div className='custom-chat-anstext d-inline-block mt-3' style={{ background: widgetDetails?.color }}>
                    <div className='d-flex align-items-center'>
                      <div className='widget-user-icon position-relative'>
                        <img src={widgetDetails?.chatbot_avtar || SIDEBAR_BOX_IMG} alt='chatbot-icon' />
                      </div>
                      <ThreeDotLoader />
                      <style>
                        {` 
                        .dot-flashing
                        {
                          background-color: ${widgetDetails?.client_bubble_color};
                        }
                        .dot-flashing::before{
                          background-color: ${widgetDetails?.client_bubble_color};
                         }
                        .dot-flashing::after {
                          background-color: ${widgetDetails?.client_bubble_color};
                         }
                         @keyframes dot-flashing {
                          0% {
                            background-color: ${widgetDetails?.client_bubble_color};
                          }
                          50%,
                          100% {
                            background-color: #5f5f5f1c;
                          }
                        }
                        
                        `}
                      </style>
                    </div>
                  </div>
                </>
              ) : (
                ''
              )}

              {showRecommendation && (
                <>
                  {showRecommendation?.map((recommendation, index) => (
                    <div key={index} className='chat-group'>
                      <button
                        className='btn chat-group-btn'
                        style={{
                          background: `${widgetDetails?.color}`,
                          color: `${widgetDetails?.agent_bubble_text_color}`,
                          border: `3px solid ${widgetDetails?.client_bubble_color}`,
                        }}
                        onClick={(e) => {
                          handleChoice(e, recommendation?.answer);
                          setShowRecommendation(null);
                        }}
                      >
                        {recommendation?.answer}
                      </button>
                    </div>
                  ))}
                  <style>
                    {`
                     .chat-group-btn:hover {
                        background: ${widgetDetails?.client_bubble_color} !important;
                        color: ${widgetDetails?.bubble_text_color} !important; 
                      }
                    `}
                  </style>
                </>
              )}
              {showForm && (
                <div className='user-box-wrapper position-relative' style={{ background: widgetDetails?.color }}>
                  <div className='user-box'>
                    <div className='user-box-img p-0' style={{ border: `3px solid ${widgetDetails?.color}` }}>
                      <div
                        className='widget-user-icon'
                        style={{
                          width: '40px',
                          height: '40px',
                          minWidth: '40px',
                          minHeight: '40px',
                        }}
                      >
                        <img src={widgetDetails?.chatbot_avtar || SIDEBAR_BOX_IMG} alt='chatbot-icon' />
                      </div>
                    </div>
                    <form id='form' onSubmit={handleFormSubmit}>
                      <div className='mb-3'>
                        <label className='opensans-medium mb-1' style={{ color: widgetDetails?.agent_bubble_text_color }}>
                          Name
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          name='name'
                          id='name'
                          required
                          autoComplete='off'
                          placeholder='Enter your name'
                          value={name}
                          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                          style={{ color: '#000000' }}
                        />
                      </div>
                      <div className='mb-3'>
                        <label className='opensans-medium mb-1' style={{ color: widgetDetails?.agent_bubble_text_color }}>
                          Email
                        </label>
                        <input
                          type='email'
                          className='form-control'
                          name='email'
                          required
                          autoComplete='off'
                          placeholder='Enter your email'
                          pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$'
                          value={email}
                          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                          style={{ color: '#000000' }}
                        />
                      </div>
                      <div className='mb-4'>
                        <label className='opensans-medium mb-1' style={{ color: widgetDetails?.agent_bubble_text_color }}>
                          Phone
                        </label>
                        <input
                          type='number'
                          className='form-control'
                          name='phone_number'
                          required
                          autoComplete='off'
                          placeholder='Enter your number'
                          maxLength='10'
                          value={phone_number}
                          onChange={(e) => {
                            if (e.target.value.length <= 10) {
                              setFormData({ ...formData, phone_number: e.target.value });
                            }
                          }}
                          style={{ color: '#000000' }}
                        />
                      </div>

                      <input
                        id='submit'
                        type='submit'
                        disabled={name === '' || phone_number === '' || email === '' || isButtonDisabled}
                        style={{
                          opacity: name === '' || phone_number === '' || email === '' ? 0.3 : 1,
                          cursor: name === '' || phone_number === '' || email === '' ? 'not-allowed' : 'pointer',
                          background: widgetDetails?.client_bubble_color,
                          color: widgetDetails?.bubble_text_color,
                        }}
                      />
                    </form>
                  </div>
                </div>
              )}

              {showCalendar.show && <CalendlyEmbed url={showCalendar.link} />}
              <ScrollToBottom scrollChatData={chatData} scrollLoader={loader} showForm={showForm} showRecommendation={showRecommendation} />
            </div>
          </div>
        </div>

        {deleteConfirmPopup && (
          <div className='delete-confirmation-popup'>
            <div
              className='confirmation-box'
              style={{
                background: widgetDetails?.background_color?.includes('http') ? `url(${widgetDetails?.background_color})` : widgetDetails?.background_color,
                backgroundSize: 'cover',
              }}
            >
              <div className='logo-wrapper'>
                <img src={widgetDetails?.chatbot_logo || CHIRPFLO_LOGO} alt='chatbot-icon' className='chatbot-logo' />
              </div>
              <p className='delete-confirmation-text' style={{ color: widgetDetails?.text_color }}>
                Are you sure you want to permanently delete and reset the chat?
              </p>
              <div className='button-group'>
                <button
                  className='btn-confirm'
                  style={{ background: widgetDetails?.client_bubble_color, color: widgetDetails?.bubble_text_color }}
                  onClick={(e) => hanldeClearData(e)}
                >
                  Yes!
                </button>
                <button
                  className='btn-cancel'
                  style={{
                    backgroundColor: widgetDetails?.color,
                    color: widgetDetails?.agent_bubble_text_color,
                    border: `2px solid ${widgetDetails?.client_bubble_color}`,
                  }}
                  onClick={() => setDeleteConfirmPopup(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        <div className='p-3'>
          {(hideButtons && hideLeadClosingButtons) || !welcomeDetails?.lead_status ? (
            <>
              {showFormButton && !isLeadButtonHide && (
                <div className='d-flex flex-column align-items-center justify-content-center pb-2 g-2' style={{ gap: '0.5rem' }}>
                  <button
                    className='btn inter-regular font14 w-100'
                    onClick={(e) => {
                      handleChoice(e, welcomeDetails?.create_lead_choice);
                      setIsLeadButtonHide(true);
                    }}
                    style={{
                      color: widgetDetails?.bubble_text_color,
                      background: widgetDetails?.client_bubble_color,
                      border: `2px solid ${widgetDetails?.client_bubble_color}`,
                      height: '40px',
                      borderRadius: '34px',
                    }}
                    disabled={isButtonDisabled}
                  >
                    {welcomeDetails?.create_lead_choice}
                  </button>

                  <button
                    className='btn inter-regular font14 w-100'
                    onClick={async () => {
                      setIsLeadButtonHide(true);
                      if (welcomeDetails?.lead_form) {
                        setShowForm(true);
                      } else {
                        const calendlyLink = await fetchCalendlyLink();
                        if (calendlyLink) {
                          setShowCalendar({ show: true, link: calendlyLink });
                        }
                      }
                    }}
                    // onClick={() => {
                    //   setShowForm(true);
                    //   setIsLeadButtonHide(true);
                    // }}
                    style={{
                      backgroundColor: widgetDetails?.color,
                      color: widgetDetails?.agent_bubble_text_color,
                      borderRadius: '34px',
                      border: 'none',
                      height: '40px',
                    }}
                    disabled={isButtonDisabled}
                  >
                    {welcomeDetails?.generate_lead_choice}
                  </button>
                </div>
              )}

              <form
                className='widget-chatbox-group d-flex justify-content-between align-items-center position-relative'
                type='submit'
                style={{ border: `1.5px solid ${widgetDetails?.client_bubble_color}` }}
              >
                <div className='chatbox-input-group'>
                  <button
                    type='button'
                    className='btn refresh-btn'
                    disabled={customDisabled}
                    onClick={() => setDeleteConfirmPopup(true)}
                    style={{ backgroundColor: widgetDetails?.client_bubble_color }}
                  >
                    <BiTrash className='icon-refresh' />
                  </button>
                </div>
                <input
                  type='text'
                  className='form-control chat-input'
                  placeholder='Type Message..'
                  name='message'
                  value={values}
                  onChange={(e) => setValues(e.target.value)}
                  onClick={props.handleBlurClick}
                  disabled={showForm || showCalendar.show}
                  style={{ color: widgetDetails?.text_color }}
                  autoComplete='off'
                />
                <style>
                  {` 
                    .widget-chatbox-group input::placeholder { 
                        color: ${widgetDetails?.text_color}; 
                    }
                  `}
                </style>
                <div className='chatbox-input-group'>
                  <button
                    type='submit'
                    className='btn send-btn'
                    disabled={customDisabled || showForm || showCalendar.show}
                    onClick={postChatData}
                    style={{ backgroundColor: widgetDetails?.client_bubble_color }}
                  >
                    <IoSend className='icon-send ms-1' />
                  </button>
                </div>
              </form>
            </>
          ) : null}
        </div>
        <div className='custom-chat-footer'>
          <h3 className='inter-regular me-2 mb-0' style={{ color: widgetDetails?.text_color }}>
            Created with
          </h3>
          <span className='inter-bold lead-summary-color mb-0 '>Chirpflo</span>
        </div>
      </div>

      {((widgetData?.pathname === '/preview' && widgetDetails?.status && !openChatbox) || (widgetData?.pathname !== '/preview' && !openChatbox)) && (
        <>
          {welBubblePopup && (
            <div className='welcome-popup d-flex flex-column align-items-end'>
              <div className={`chatbot-welcome-body align-items-end ${hideButtons && welcomeDetails?.lead_choice != null && 'p-0'}`}>
                {welcomeDetails?.welcome_msg != null && !hideButtons && (
                  <div className={`d-flex align-items-center justify-content-end ${showForm && 'mt-3'}`}>
                    {showForm && (
                      <div className='chatbox-user-icon me-3'>
                        <img src={widgetDetails?.chatbot_avtar || SIDEBAR_BOX_IMG} alt='chatbot-icon' />
                      </div>
                    )}
                    <div
                      className={`opensans-regular welcome-text-box welcome-text text-white ${welcomeDetails?.lead_status && 'p-4'}`}
                      style={{ background: widgetDetails?.color, boxShadow: '0px 0px 15px 10px rgba(0, 0, 0, 0.1), 1px 0px 6px 5px rgba(0, 0, 0, 0.1)' }}
                    >
                      <div className='bubble-close-icon' style={{ background: widgetDetails?.client_bubble_color }} onClick={() => setWelBubblePopup(false)}>
                        <IoMdClose style={{ fontSize: '20px', color: widgetDetails?.bubble_text_color }} />
                      </div>
                      <div className={`d-flex align-items-center ${!welcomeDetails?.lead_status ? 'justify-content-between' : 'justify-content-center mt-3'}`}>
                        <div className={`${!welcomeDetails?.lead_status ? 'text-start' : 'text-center'}`} style={{ color: widgetDetails?.agent_bubble_text_color }}>
                          <MessageComponent message={welcomeDetails?.welcome_msg} />
                        </div>
                        <div
                          className={`chatbox-user-icon ${!welcomeDetails?.lead_status ? 'cursor-pointer' : 'user-box-img p-0'}`}
                          style={{ width: '55px', height: '55px', minWidth: '55px', minHeight: '55px', background: widgetDetails?.color }}
                          onClick={() => !welcomeDetails?.lead_status && setOpenChatbox(!openChatbox)}
                        >
                          <img src={widgetDetails?.chatbot_avtar || SIDEBAR_BOX_IMG} alt='chatbot-icon' />
                        </div>
                      </div>

                      {!hideButtons && welcomeDetails?.lead_choice != null && (
                        <div className='d-flex align-items-center flex-column buttonWrap'>
                          {welcomeDetails?.lead_choice.map((choice, index) => (
                            <React.Fragment key={index}>
                              <button
                                className='btn btn-submit-bubble inter-regular font14'
                                style={{
                                  marginRight: index === 0 ? '10px' : '0px',
                                  marginTop: '1rem',
                                  color: index === 0 ? widgetDetails?.bubble_text_color : widgetDetails?.client_bubble_color,
                                  background: index === 0 ? widgetDetails?.client_bubble_color : widgetDetails?.color,
                                  border: `2px solid ${widgetDetails?.client_bubble_color}`,
                                  height: '40px',
                                  maxWidth: '100%',
                                  borderRadius: '34px',
                                }}
                                onClick={
                                  index === 0
                                    ? (e) => handleChoice(e, choice)
                                    : (e) => {
                                        handleChoice(e, choice);
                                        setShowFormButton(true);
                                      }
                                }
                              >
                                {choice}
                              </button>
                            </React.Fragment>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {!welBubblePopup && (
            <div className='chatbot-popup d-flex flex-column align-items-end'>
              <div className='d-flex align-items-center'>
                {widgetData?.pathname !== '/preview' && <p className='mb-0 me-4 font16 text-white opensans-regular'>Test your agent here</p>}
                <div className='chatbot-popup-icon' onClick={() => setOpenChatbox(!openChatbox)}>
                  <img src={widgetDetails?.launcher_icon || CHATBOT_ICON} alt='chatbot-icon' />
                </div>
              </div>
            </div>
          )}
        </>
      )}

      {retrainPopup && (
        <div className='custom-popup'>
          <div className='popup-content'>
            <div className={'popup-head text-center'}>
              <h4 className='font18 color-theme-darkLightblue opensans-regular'>Oops just gotta quickly re -train your agent. </h4>
            </div>
            <hr />
            <div className='popup-footer text-center'>
              <button
                className='btn success-btn text-capitalize me-2'
                style={{ background: 'linear-gradient(to right, #579df1, #69dfe1)', border: 'none' }}
                onClick={() => hanldeClearData()}
              >
                Retrain
              </button>
              <button className='btn danger-btn  text-capitalize' data-dismiss='modal' onClick={() => setRetrainPopup(false)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(WidgetChatbot);
