import React, { useContext, useEffect, useRef, useState } from 'react';
import '../assets/styles/Chatbot.css';
import COUNTRYLIST from '../Country_Nationality_List.json';
import { clearLocalStorage, getLocalStorage, getWithToken, patchWithToken, postWithToken, removeClassToBody } from '../API/Api';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../components/Loader';
import EditChatBox from '../components/EditChatBox';
import { subContext } from '../App';

const ValidationErrors = {
  empty: {
    company: 'Company name required',
    country: 'Country name required',
    city: 'City name required',
    address: 'Address required',
    phone_number: 'Phone Number name required',
  },
};

const countCharacters = (text) => {
  if (text) {
    return text.length;
  }
  return 0;
};
const maxLength = 1500;

const ChatBotProfile = () => {
  const [loader, setLoader] = useState(false);
  const subsFlag = useContext(subContext);
  const Navigate = useNavigate();
  const { state } = useLocation();
  const [companyValues, setCompanyValues] = useState({
    company: '',
    country: '',
    city: '',
    address: '',
    phone_number: '',
    product_price: '',
    website: '',
    about_company: '',
    booking_link: '',
  });

  const [errors, setErrors] = useState({
    company: '',
    country: '',
    city: '',
    address: '',
    phone_number: '',
  });

  const { company, country, city, phone_number, address, website, booking_link, product_price, about_company } = companyValues;

  const [apiTokenData, setApiTokenData] = useState('');
  const [botId, setBotId] = useState('');
  const [childData, setChildData] = useState(false);
  const [blurPopup, setBlurPopup] = useState(false);
  const [blurChange, setBlurChange] = useState(false);

  const FullName = JSON.parse(getLocalStorage('FullName'));

  const concernedElementRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    removeClassToBody('no-chat');
    let token = getLocalStorage('apiToken');
    if (token) {
      setApiTokenData(JSON.parse(token));
      getBotDetails(JSON.parse(token));
    } else {
      Navigate('/login');
    }
  }, [childData]);

  const companyHandleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'product_price' || name === 'about_company') {
      if (value.length >= maxLength) {
        toast.dismiss();
        toast.info('Character limit exceeded! Please keep it under 1500 characters.');
      }
    }

    setCompanyValues({ ...companyValues, [name]: value });
    setErrors({ ...errors, [name]: '' });
    setBlurChange(true);
  };

  const handleBlurClick = (event) => {
    if (blurChange) {
      if (concernedElementRef.current && concernedElementRef.current.contains(event.target)) {
        console.log('Clicked Inside');
      } else {
        setBlurPopup(true);
      }
    }
  };

  const getBotDetails = (token) => {
    setLoader(true);
    getWithToken('company/', token)
      .then((response) => {
        if (response.status === 201) {
          setBotId(response?.data?.id);
          setCompanyValues({
            ...companyValues,
            company: response?.data?.company,
            country: response?.data?.country,
            city: response?.data?.city,
            address: response?.data?.address,
            phone_number: response?.data?.phone_number,
            product_price: response?.data?.product_price,
            website: response?.data?.website,
            about_company: response?.data?.about_company,
            booking_link: response?.data?.booking_link,
          });
          setLoader(false);
        } else if (response.code === 'token_not_valid') {
          clearLocalStorage();
        }
        if (response.status === 200) {
          setLoader(false);
        }
      })
      .catch((error) => {
        toast.error('Something went wrong');
      });
  };

  const companyHandleClick = () => {
    const tempErrors = { ...errors };
    Object.keys(companyValues).forEach((key) => {
      if (!companyValues[key]) {
        tempErrors[key] = ValidationErrors.empty[key];
      }
    });
    setErrors(tempErrors);

    if (Object.values(tempErrors).filter((obj) => !!obj).length > 0) {
      return false;
    }
    setLoader(true);

    if (botId) {
      patchWithToken('company/' + botId + '/', apiTokenData, companyValues)
        .then((response) => {
          if (response.status === 200) {
            getBotDetails(apiTokenData);
            toast.success(response.message);
            setLoader(false);
            setBlurChange(false);
            subsFlag.setUpdateValue('1');
          } else if (response.status === 400) {
            setLoader(false);
            toast.error(response.message);
          }
        })
        .catch((error) => {
          toast.error('Something went wrong');
          setLoader(false);
        });
    } else {
      postWithToken('company/', apiTokenData, companyValues)
        .then((response) => {
          if (response.status === 201) {
            getBotDetails(apiTokenData);
            toast.success(response.message);
            setLoader(false);
            subsFlag.setUpdateValue('1');
          } else if (response.status === 400) {
            setLoader(false);
            toast.error(response.message);
          } else if (response.code === 'token_not_valid') {
            clearLocalStorage();
          }
        })
        .catch((error) => {
          setLoader(false);
          toast.error('Something went wrong');
        });
    }
  };
  const onFormSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <div className='row m-0 mt-5 ps-md-5 ps-sm-3 ps-3 pe-md-3 pe-sm-3 pe-3 mb-5'>
        <div className='col-xxl-7 col-xl-7 col-lg-12 col-md-12 col-sm-12 col-12 p-0'>
          <div className='chatbox-field bg-themeLightBlue'>
            <div className='mb-5'>
              <h4 className='text-white opensans-bold'>Hey, {FullName}</h4>
              <p className='mb-0 opensans-regular color-theme-darkLightblue font18'>
                Here, you can make any adjustments to your Chirpflo Agents company knowledge. After you make any changes, don't forget to RE-Train your agent.✅
              </p>
            </div>

            <div className='box'></div>
            <form onSubmit={onFormSubmit} className='px-2 px-md-3 px-lg-2 px-xl-3 px-xxl-5'>
              <div className='chatbox-input-text input-GPT new'>
                <label className='opensans-medium'>Company Name*</label>
                <div className='chatbox-input-field'>
                  <input
                    type='text'
                    placeholder='Luna Compnay Ltd'
                    className='form-control chatbox-input box-shadow-hover'
                    id='company'
                    name='company'
                    value={company}
                    autoComplete='off'
                    onChange={companyHandleChange}
                  />
                  <div className='chat-error'>{errors.company && <p className='text-danger insta-smart-error'>{errors.company}</p>}</div>
                </div>
              </div>

              <div className='chatbox-input-text input-GPT new'>
                <label className='opensans-medium'>Address*</label>
                <div className='chatbox-input-field'>
                  <input
                    type='text'
                    placeholder='13th Street. 47 W 13th St'
                    className='form-control chatbox-input box-shadow-hover'
                    id='address'
                    name='address'
                    value={address}
                    autoComplete='off'
                    onChange={companyHandleChange}
                  />
                  <div className='chat-error'>{errors.address && <p className='text-danger insta-smart-error'>{errors.address}</p>}</div>
                </div>
              </div>

              <div className='chatbox-input-text input-GPT new'>
                <label className='opensans-medium'>City Name*</label>
                <div className='chatbox-input-field'>
                  <input
                    type='text'
                    placeholder='New York'
                    className='form-control chatbox-input box-shadow-hover'
                    id='city'
                    name='city'
                    value={city}
                    autoComplete='off'
                    onChange={companyHandleChange}
                  />
                  <div className='chat-error'>{errors.city && <p className='text-danger insta-smart-error'>{errors.city}</p>}</div>
                </div>
              </div>

              <div className='chatbox-input-text input-GPT new'>
                <label className='opensans-medium'>Country*</label>
                <div className='chatbox-input-field'>
                  <select className='form-control box-shadow-hover' name='country' value={country} onChange={companyHandleChange}>
                    <option selected hidden>
                      Choose the country
                    </option>
                    {COUNTRYLIST.map((row, i) => (
                      <option key={i}>{row?.en_short_name}</option>
                    ))}
                  </select>
                  <div className='chat-error'>{errors.country && <p className='text-danger insta-smart-error'>{errors.country}</p>}</div>
                </div>
              </div>

              <div className='chatbox-input-text input-GPT new'>
                <label className='opensans-medium'>Phone Number*</label>
                <div className='chatbox-input-field'>
                  <input
                    type='tel'
                    placeholder='xxxxxxxxxxx'
                    className='form-control chatbox-input box-shadow-hover'
                    id='phone_number'
                    name='phone_number'
                    value={phone_number}
                    autoComplete='off'
                    maxLength={12}
                    onChange={(e) => {
                      if (isNaN(e.target.value)) {
                        return false;
                      } else {
                        companyHandleChange(e);
                      }
                    }}
                  />
                  <div className='chat-error'>{errors.phone_number && <p className='text-danger insta-smart-error'>{errors.phone_number}</p>}</div>
                </div>
              </div>

              <div className='chatbox-input-text input-GPT new'>
                <label className='opensans-medium'>Website</label>
                <div className='chatbox-input-field'>
                  <input
                    type='text'
                    placeholder='https://chirpflo.com/'
                    className='form-control chatbox-input box-shadow-hover'
                    id='website'
                    name='website'
                    value={website}
                    autoComplete='off'
                    onChange={companyHandleChange}
                  />
                </div>
              </div>

              <div className='chatbox-input-text input-GPT new'>
                <label className='opensans-medium'>Booking Link</label>
                <div className='chatbox-input-field'>
                  <input
                    type='text'
                    placeholder='https://chirpflo.com/'
                    className='form-control chatbox-input box-shadow-hover'
                    id='booking_link'
                    name='booking_link'
                    value={booking_link}
                    autoComplete='off'
                    onChange={companyHandleChange}
                  />
                </div>
              </div>

              <div className='chatbox-input-text input-GPT new'>
                {/* <label className='opensans-medium'>Product Name & Price</label> */}
                <div className='d-flex justify-content-between align-items-center'>
                  <label className='mb-1 opensans-bold text-white'>Product Name & Price</label>
                  <p className='mb-0 opensans-regular color-theme-darkLightblue font18 text-end'>({countCharacters(product_price) || 0} / 1500)</p>
                </div>
                <div className='chatbox-input-field'>
                  <textarea
                    className='form-control chatbox-input textarea-height box-shadow-hover'
                    placeholder='Enter  your product name & price here'
                    id='product_price'
                    name='product_price'
                    value={product_price}
                    autoComplete='off'
                    onChange={companyHandleChange}
                    maxLength={1500}
                  ></textarea>
                </div>
              </div>

              <div className='chatbox-input-text input-GPT new'>
                {/* <label className='opensans-medium'>About Company</label> */}
                <div className='d-flex justify-content-between align-items-center'>
                  <label className='mb-1 opensans-bold text-white'>About Company</label>
                  <p className='mb-0 opensans-regular color-theme-darkLightblue font18 text-end'>({countCharacters(about_company) || 0} / 1500)</p>
                </div>
                <div className='chatbox-input-field'>
                  <textarea
                    rows='10'
                    className='form-control chatbox-input textarea-height box-shadow-hover'
                    placeholder='Describe about your company '
                    id='about_company'
                    name='about_company'
                    value={about_company}
                    autoComplete='off'
                    onChange={companyHandleChange}
                    maxLength={1500}
                  ></textarea>
                </div>
              </div>
              <div className='d-flex flex-wrap align-items-center justify-content-between'>
                <button className='btn btn-submit-login me-sm-3' id='firstButton' onClick={companyHandleClick}>
                  Train Agent
                </button>
                {state != null && state?.faqFlag && (
                  <button
                    className='btn btn-submit-login bg-themeLightGreenGradient'
                    onClick={() => Navigate('/chatbot/knowledgebase', { state: { faqFlag: true, id: state?.id, length: state?.length } })}
                  >
                    Continue
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
        <div className='col-xxl-5 col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12 mt-4 mt-xl-0 px-sm-3 px-0'>
          <EditChatBox passChildData={setChildData} ref={concernedElementRef} handleBlurClick={handleBlurClick} />
        </div>
      </div>
      {loader && (
        <div className='loader-center'>
          <Loader />
        </div>
      )}

      {blurPopup && (
        <div className='custom-popup'>
          <div className='popup-content'>
            <div className={'popup-head text-center'}>
              <h4>Train Agent Again</h4>
            </div>
            <hr />
            <div className='popup-footer text-center'>
              <button
                className='btn danger-btn mx-1 text-capitalize'
                data-dismiss='modal'
                onClick={() => {
                  setBlurPopup(false);
                  setBlurChange(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ChatBotProfile;
