import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import HUBSPOT_ICON from '../assets/icons/ChirpfloIcon/Hubspot-icon.svg';
import GOHIGHLEVEL_ICON from '../assets/icons/ChirpfloIcon/GoHighLevel-icon.svg';
import CALENDLY_ICON from '../assets/icons/ChirpfloIcon/Calendly-icon.svg';
import { useNavigate } from 'react-router-dom';
import { getLocalStorage, getWithToken, patchWithToken, postWithToken } from '../API/Api';
import { toast } from 'react-toastify';
import { FaCheckCircle } from 'react-icons/fa';
import IntegrationModal from '../components/IntegrationModal';
import { IoMdClose } from 'react-icons/io';
import CHIRPFLO_ICON from '../assets/icons/ChirpfloIcon-V2.0/chirpflobold-icon.svg';
import EditChatBox from '../components/EditChatBox';
import Loader from '../components/Loader';

const IntegrationCard = ({ title, description, integrationType, isConnected, onConnect, imageUrl }) => {
  return (
    <div className='custom-hover rounded-5'>
      <div className='card p-3 shadow-sm rounded-5' style={{ backgroundColor: '#0f0823', minHeight: '300px' }}>
        <div className='d-flex justify-content-between align-items-center mb-3'>
          <div className='d-flex align-items-center justify-content-between w-100'>
            <img src={imageUrl} alt='icon' className='me-2' style={{ height: '35px' }} />
          </div>
        </div>
        <div className='d-flex flex-column justify-content-center mt-3'>
          <h5 className='mb-0 opensans-bold text-white'>
            {title}
            <span className='small'>
              <FaCheckCircle className='text-success mx-2' />
              <span className='font14'>{integrationType}</span>
            </span>
          </h5>
          <p className='text-muted mb-0 mt-2'>{description}</p>
        </div>
        <div className='text-end mt-auto'>
          {/* {title === 'Calendly' ? (
            <button className='btn btn-warning rounded-pill text-white px-3 py-2' style={{ minWidth: '135px' }}>
              Coming Soon
            </button>
          ) : ( */}
          <button className={`btn ${isConnected ? 'btn-success' : 'btn-primary'} rounded-pill px-3 py-2`} onClick={onConnect} style={{ minWidth: '135px' }}>
            {isConnected ? 'Connected' : 'Connect'}
          </button>
          {/* )} */}
        </div>
      </div>
    </div>
  );
};

const Integrations = () => {
  const [editLeadPopup, setEditLeadPopup] = useState(false);
  const [modalType, setModalType] = useState('');
  const [loader, setLoader] = useState(false);
  const [apiTokenData, setApiTokenData] = useState('');
  const [botBubblePopup, setBotBubblePopup] = useState(false);
  const [values, setValues] = useState({
    ghl_api_key: '',
    ghl_location_id: '',
    hubspot_api_key: '',
    calendly_api_key: '',
    calendly_event_id: '',
  });

  const [calendlyEvents, setCalendlyEvents] = useState([]);
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    let token = getLocalStorage('apiToken');
    if (token) {
      const parsedToken = JSON.parse(token);
      setApiTokenData(parsedToken);
      fetchGHLConfigurationDetails(parsedToken);
    } else {
      navigate('/login');
    }
  }, []);

  useEffect(() => {
    if (values.calendly_api_key) {
      fetchCalendlyConfigurationDetails(values.calendly_api_key);
    } else {
      setCalendlyEvents([]);
    }
  }, [values.calendly_api_key]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'calendly_api_key') {
      setValues({ ...values, [name]: value, calendly_event_id: '' });
    } else {
      setValues({ ...values, [name]: value });
    }
    setErrors({ ...errors, [name]: '' });
  };

  const handleSaveClick = async () => {
    try {
      const payload = {
        ghl_api_key: values.ghl_api_key,
        ghl_location_id: values.ghl_location_id,
        hubspot_api_key: values.hubspot_api_key,
        calendly_api_key: values.calendly_api_key,
        calendly_event_id: values.calendly_event_id,
      };

      let response;
      if (values?.id) {
        response = await patchWithToken(`crm_configurations/${values.id}`, apiTokenData, payload);
      } else {
        response = await postWithToken('crm_configurations', apiTokenData, payload);
      }

      if (response.status === 200 || response.status === 201) {
        fetchGHLConfigurationDetails(apiTokenData);
        toast.success(response.message);
        setEditLeadPopup(false);
      } else {
        toast.error(response.message || 'Unexpected error occurred');
      }
    } catch (error) {
      console.error('Error saving configuration:', error);
      toast.error('Something went wrong while saving.');
    }
  };

  const fetchGHLConfigurationDetails = (token) => {
    setLoader(true);
    getWithToken('crm_configurations', token)
      .then((response) => {
        if (response.status === 200 && response.data?.length > 0) {
          setValues(response.data[0]);
        }
      })
      .catch((error) => console.log('error: ', error))
      .finally(() => setLoader(false));
  };

  const fetchCalendlyConfigurationDetails = (apiKey) => {
    postWithToken('get_calendly_events', apiTokenData, { calendly_token: apiKey })
      .then((response) => {
        if (response.status === 200 && response.data?.length > 0) {
          setCalendlyEvents(response.data);
        } else {
          setCalendlyEvents([]);
        }
      })
      .catch((error) => console.log('error: ', error));
  };

  if (loader)
    return (
      <div className='loader-center'>
        <Loader />
      </div>
    );
  return (
    <div className='chatbot-container'>
      <div className='chatbot-fixed header-responsive'>
        <div className='d-block d-sm-flex flex-wrap align-items-center justify-content-between'>
          <div className=''>
            <h3 className='opensans-bold mb-0'>Integrations App</h3>
          </div>
          <div className='d-flex justify-content-end responsive-header-width'>
            <Header />
          </div>
        </div>
      </div>
      <div className='row m-0 mt-5 ps-md-5 ps-sm-3 ps-3 pe-md-3 pe-sm-3 pe-3 mb-5'>
        <div className='col-xxl-8 col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12 p-0'>
          <div className='chatbox-field bg-themeLightBlue p-md-4 p-sm-3 p-3'>
            <div className='mb-5'>
              <h4 className='text-white opensans-bold mb-4'>Seamlessly Connect Your Favorite Tools</h4>
              <p className='mb-0 opensans-regular color-theme-darkLightblue font18'>
                Boost efficiency and streamline your workflow with our powerful integrations. From CRMs and automation tools to calendars and marketing platforms, we make it easy
                to connect the apps you rely on. Whether you're managing leads, scheduling appointments, or automating follow-ups, our growing list of integrations has you covered.
              </p>
              {!botBubblePopup && (
                <div className='chirpflo-box p-3 p-lg-3 p-xl-4 p-xxl-5 mt-5'>
                  <div className='bubble-close-icon' style={{ background: '#69dfe1', top: '5px', right: '11px' }} onClick={() => setBotBubblePopup(true)}>
                    <IoMdClose style={{ fontSize: '20px', color: '#000000' }} />
                  </div>
                  <div class='chirpflo-title opensans-regular '>Chirpflo Ai</div>
                  <p class='chirpflo-pro-tip opensans-regular mb-0 font18 mt-3'>
                    <span className='opensans-bold'> PRO TIP:</span> Save time by automating repetitive tasks! Integrate your CRM to trigger follow-ups, reminders, and marketing
                    campaigns without lifting a finger..
                  </p>
                  <img src={CHIRPFLO_ICON} alt='chirpflo-icon' />
                </div>
              )}
            </div>
            <div className='row g-3 d-flex flex-wrap'>
              <div className='col-xxl-4 col-lg-6 col-md-6 col-12'>
                <IntegrationCard
                  title='HubSpot'
                  description='CRM platform that helps businesses grow with inbound marketing and sales automation.'
                  integrationType={'CRM, Automations'}
                  isConnected={!!values.hubspot_api_key}
                  onConnect={() => {
                    setEditLeadPopup(true);
                    setModalType('hubspot');
                  }}
                  imageUrl={HUBSPOT_ICON}
                />
              </div>
              <div className='col-xxl-4 col-lg-6 col-md-6 col-12'>
                <IntegrationCard
                  title='GoHighLevel'
                  description='All-in-one marketing and CRM platform for agencies and businesses.'
                  integrationType={'CRM, Automations'}
                  isConnected={!!values.ghl_api_key}
                  onConnect={() => {
                    setEditLeadPopup(true);
                    setModalType('ghl');
                  }}
                  imageUrl={GOHIGHLEVEL_ICON}
                />
              </div>
              <div className='col-xxl-4 col-lg-6 col-md-6 col-12'>
                <IntegrationCard
                  title='Calendly'
                  description='Simplify scheduling and automate meeting bookings with Calendly’s seamless calendar integration.'
                  integrationType={'Calender, Booking'}
                  isConnected={!!values.calendly_api_key}
                  onConnect={() => {
                    setEditLeadPopup(true);
                    setModalType('calendly');
                  }}
                  imageUrl={CALENDLY_ICON}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 mt-4 mt-xl-0 px-sm-3 px-0'>
          <EditChatBox />
        </div>
      </div>
      <IntegrationModal
        isOpen={editLeadPopup}
        onClose={() => setEditLeadPopup(false)}
        modalType={modalType}
        values={values}
        onChange={handleInputChange}
        onSave={handleSaveClick}
        calendlyEvents={calendlyEvents}
      />
    </div>
  );
};

export default Integrations;
